import item6 from "../../img/products/item6.png";
import item7 from "../../img/products/item7.png";
import item9 from "../../img/products/PayTorSS-1128.png";
import item10 from "../../img/products/PayTorRS-1007.png";
import item11 from "../../img/products/PayTorBB-2008.png";
import item12 from "../../img/products/PayTorFL-1008.png";
import item13 from "../../img/products/PayTorGS-1118.png";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Dialog,
    DialogFooter,
    DialogHeader,
    Typography
} from "@material-tailwind/react";

import {BadgeDefault} from "../Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {fetchShop, setShop, setShopremove, Shop_Item} from "../../redux/features/shop/shopSlice";
import {IconClose} from "../../img/Component_svg/Component_svg";

const  DialogDefault = (elem) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const handleAddItem = (elem) => {
        dispatch(setShop(elem));
    };

    const handleRemoveItem = (id) => {
        dispatch(setShopremove(id));
    };

    return (
        <>
            <a className="flex justify-start items-end underline" style={{color:'#0000ffa1'}} onClick={handleOpen}>Տեսնել ավելին ...</a>
            <Dialog open={open} handler={handleOpen} className="w-full max-w-[0vw]">
                <DialogHeader className="DialogDefault_name flex justify-between p-2 border-b-purple-950">
                    <div>{elem.name}</div> <div className={"cursor-pointer"} onClick={() => setOpen(false)}><IconClose/></div>
                </DialogHeader>
                <Card className="printer_element_content w-full grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer rounded-none shadow-none px-5 py-5">
                    {/* Изображение */}
                    <CardHeader
                        shadow={false}
                        floated={false}
                        className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center mt-0"
                    >
                        <img src={elem.img} alt="product" className="w-full object-cover" />
                    </CardHeader>

                    {/* Контент с текстом */}
                    <Typography color="gray" className="font-normal flex justify-center items-center">
                        {elem.description}
                    </Typography>
                </Card>

                <DialogFooter className='px-4 pb-4 pt-3'>
                    <Button variant="text" color="red" className="mr-1" onClick={() => {
                        handleRemoveItem(elem.id)
                        handleOpen()
                    }}>
                        <span>{t("delete")}</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => {
                        handleAddItem(elem)
                        handleOpen()
                    }}>
                        <span>{t("Add_to_Cart")}</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

function RetailStoreEquipment({ setIsTrue }) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchShop())
    }, []);

  const { t } = useTranslation();
  const items = [

        {
            id:11,
            img: item6,
            type: t("Scanner"),
            name: "PayTor FL-1007",
            price:"20 000 դ․",
            description:t("PayTor_FL_1007_info"),
            description_1:t("PayTor_FL_1007_Printing_method"),
            description_2:t("PayTor_FL_1007_Printing_speed"),
            description_3:t("PayTor_FL_1007_Resolution"),
            description_4:t("PayTor_FL_1007_Dimensions"),
            description_5:t("PayTor_FL_1007_Maximum"),
            description_6:t("PayTor_FL_1007_Interface"),
            description_7:t("PayTor_FL_1007_Receipt_strip_width"),
            description_8:t("PayTor_FL_1007_Print_head_resource"),
            description_9:t("PayTor_FL_1007_resource")

        },
        {
            id:12,
            img: item7,
            type: t("Scanner"),
            name: "PayTor ES-1007 ",
            price:"20 000 դ․",
            description:t("PayTor_ES_1007_info"),
            description_1:t("PayTor_ES_1007_Printing_method"),
            description_2:t("PayTor_ES_1007_Printing_speed"),
            description_3:t("PayTor_ES_1007_Resolution"),
            description_4:t("PayTor_ES_1007_Dimensions"),
            description_5:t("PayTor_ES_1007_Maximum"),
            description_6:t("PayTor_ES_1007_Interface"),
            description_7:t("PayTor_ES_1007_Receipt_strip_width"),
            description_8:t("PayTor_ES_1007_Print_head_resource"),
            description_9:t("PayTor_ES_1007_resource")
        },
        // 
        {
            id:14,
            img: item9,
            type: t("Scanner"),
            name: " PayTor SS-1128 DataMatrix",
            price:"20 000 դ․",
            description:  t("PayTorSS_1128_DataMatrix_info"),
            description_1:t("PayTorSS_1128_DataMatrix_Printing_method"),
            description_2:t("PayTorSS_1128_DataMatrix_Printing_speed"),
            description_3:t("PayTorSS_1128_DataMatrix_Resolution"),
            description_4:t("PayTorSS_1128_DataMatrix_Dimensions"),
            description_5:t("PayTorSS_1128_DataMatrix_Maximum"),
            description_6:t("PayTorSS_1128_DataMatrix_Interface"),
            description_7:t("PayTorSS_1128_DataMatrix_Receipt_strip_width"),
            description_8:t("PayTorSS_1128_DataMatrix_Print_head_resource"),
            description_9:t("PayTorSS_1128_DataMatrix_resource")
        },
        {
            id:15,
            img: item10,
            type: t("Scanner"),
            name: " PayTor RS-1007 QR DataMatrix ",
            price:"20 000 դ․",
            description:  t("PayTor_RS-1007_QR_info"),
            description_1:t("PayTor_RS-1007_QR_Printing_method"),
            description_2:t("PayTor_RS-1007_QR_Printing_speed"),
            description_3:t("PayTor_RS-1007_QR_Resolution"),
            description_4:t("PayTor_RS-1007_QR_Dimensions"),
            description_5:t("PayTor_RS-1007_QR_Maximum"),
            description_6:t("PayTor_RS-1007_QR_Interface"),
            description_7:t("PayTor_RS-1007_QR_Receipt_strip_width"),
            description_8:t("PayTor_RS-1007_QR_Print_head_resource"),
            description_9:t("PayTor_RS-1007_QR_resource")
        },
        {
            id:16,
            img: item11,
            type: t("Scanner"),
            name: "PayTor BB-2008 QR Datamatrix",
            price:"20 000 դ․",
            description:  t("PayTor_BB_2008_QR_info"),
            description_1:t("PayTor_BB_2008_QR_Printing_method"),
            description_2:t("PayTor_BB_2008_QR_Printing_speed"),
            description_3:t("PayTor_BB_2008_QR_Resolution"),
            description_4:t("PayTor_BB_2008_QR_Dimensions"),
            description_5:t("PayTor_BB_2008_QR_Maximum"),
            description_6:t("PayTor_BB_2008_QR_Interface"),
            description_7:t("PayTor_BB_2008_QR_Receipt_strip_width"),
            description_8:t("PayTor_BB_2008_QR_Print_head_resource"),
            description_9:t("PayTor_BB_2008_QR_resource")

        },
        {
            id:17,
            img: item12,
            type: t("Scanner"),
            name: " PayTor FL-1008 QR DataMatrix",
            price:"20 000 դ․",
            description:  t("PayTor_FL_1008_QR_info"),
            description_1:t("PayTor_FL_1008_QR_Printing_method"),
            description_2:t("PayTor_FL_1008_QR_Printing_speed"),
            description_3:t("PayTor_FL_1008_QR_Resolution"),
            description_4:t("PayTor_FL_1008_QR_Dimensions"),
            description_5:t("PayTor_FL_1008_QR_Maximum"),
            description_6:t("PayTor_FL_1008_QR_Interface"),
            description_7:t("PayTor_FL_1008_QR_Receipt_strip_width"),
            description_8:t("PayTor_FL_1008_QR_Print_head_resource"),
            description_9:t("PayTor_FL_1008_QR_resource")
        },
        {
            id:18,
            img: item13,
            type: t("Scanner"),
            name: " PayTor GS-1118 QR DataMatrix ",
            price:"20 000 դ․",
            description:  t("PayTor_GS-1118_QR_info"),
            description_1:t("PayTor_GS-1118_QR_Printing_method"),
            description_2:t("PayTor_GS-1118_QR_Printing_speed"),
            description_3:t("PayTor_GS-1118_QR_Resolution"),
            description_4:t("PayTor_GS-1118_QR_Dimensions"),
            description_5:t("PayTor_GS-1118_QR_Maximum"),
            description_6:t("PayTor_GS-1118_QR_Interface"),
            description_7:t("PayTor_GS-1118_QR_Receipt_strip_width"),
            description_8:t("PayTor_GS-1118_QR_Print_head_resource"),
            description_9:t("PayTor_GS-1118_QR_resource")
        },
        
    ]
  const Shop_data = useSelector(Shop_Item)

  const handleAddItem = (elem) => {
      dispatch(setShop(elem));
  };

  return (
    <div  id='printers'>
        <div className={'flex justify-between items-center mb-5'}>
            <h2> {t("Scanners")}</h2>
            <BadgeDefault Shop_data={Shop_data}  setIsTrue={setIsTrue} />
        </div>

      <div className='printer_grid grid grid-cols-1 md:grid-cols-2 gap-6'>
        {
            items.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                        <Card className="printer_element_content w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                            {/* Изображение */}
                            <CardHeader
                                shadow={false}
                                floated={false}
                                className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                            >
                                <img
                                    src={elem.img}
                                    alt="product"
                                    className="w-full object-cover"
                                />
                            </CardHeader>

                            {/* Контент карточки */}
                            <div className="grid grid-rows-[auto_1fr_auto] gap-4">

                                <CardBody className="p-0">
                                    <Typography variant="h6" color="gray" className=" uppercase ">
                                        {elem.name}
                                    </Typography>
                                    <Typography variant="h4" color="blue-gray" className=" text-base ">
                                        {elem.description_1}
                                    </Typography>
                                    <Typography color="gray" className="font-normal" >
                                        <ul>
                                            <li>{elem.description_2}</li>
                                            <li>{elem.description_3}</li>
                                            <li>{elem.description_4}</li>
                                            <li>{elem.description_5}</li>
                                            <li>{elem.description_6}</li>
                                            <li>{elem.description_7}</li>
                                            <li>{elem.description_8}</li>
                                            <li>{elem.description_9}</li>
                                        </ul>
                                        {DialogDefault(elem)}
                                    </Typography>
                                    <Typography variant="h4" color="blue-gray" className=" text-base ">
                                        {elem.price}
                                    </Typography>
                                </CardBody>

                                {/* Футер карточки с кнопкой */}
                                <CardFooter className="w-full flex justify-start p-0">
                                    <Button
                                        ripple={false}
                                        fullWidth={true}
                                        className="CardFooter_button w-[100%] bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                        onClick={() => {
                                            handleAddItem(elem)
                                        }}
                                        style={Array.isArray(Shop_data) &&  Shop_data?.some(el => el.id === elem.id)
                                            ? {backgroundColor: "green", color: "#ffffff"}
                                            : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                        }
                                    >
                                        {t("Add_to_Cart")}
                                    </Button>
                                </CardFooter>
                            </div>
                        </Card>
                    </div>
            )
            })
        }
      </div>
    </div>
  )
}

export default RetailStoreEquipment
