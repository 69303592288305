import "./Popup.css";
import bacground from "../../img/Vector.svg"
import {Fragment, useEffect, useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import Accepting from "./accepting";
import close from "../../img/menu-2.png";
import {useTranslation} from "react-i18next";
import {fetchShop, Shop_Item} from "../../redux/features/shop/shopSlice";
import {useDispatch, useSelector} from "react-redux";

function Popup({isTrue,setIsTrue}) {
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(fetchShop())
  }, []);

  const Shop_data = useSelector(Shop_Item)
  const { t } = useTranslation();
  const [error, setError] = useState(false)
  const [isAccepted,setisAccepted]=useState(false)
  const [inputsDate, setInputsDate] = useState({
    Name:'',
    PhoneNumber: '',
    Email: '',
    message:'',
  })
  const [isLoading,setIsLoading] = useState(false)
  const pop = useRef(null)
  const form = useRef(null)

  const RequestTimer = () =>{
    setTimeout(() => {
      setisAccepted(false)
    }, 3000);
    return () => clearTimeout();
  }

  async function Submit(e) {
    e.preventDefault()
       setIsLoading(true)
        if(inputsDate.Name && inputsDate.PhoneNumber ){
        setError(false)
     await emailjs.sendForm('service_8bsij0q', 'template_vbnbxsg',form.current, 'eSGotVBd4MRUUQjJ_')
          .then((result) => {
            setIsLoading(false)
            if(result.text === 'OK' && result.status === 200){
              setIsTrue(false)
              setisAccepted(true)
              RequestTimer()
            }
          }, (error) => {

          })}else{
            setError(true)
        }
  }

  function Change (e) {
    setError(false)
    setIsLoading(false)

  }

  const handleChange = (e) => {
    const newPhoneNumber = e.target.value;
    const pattern =  /^(?!.*(\.*\+|.*e))[0-9+]*$/;
    if (pattern.test(newPhoneNumber)) {
       setInputsDate({...inputsDate,PhoneNumber: newPhoneNumber})
    }
  };

    return (
    
      <Fragment>
          { isTrue ? <div className="popup">
          <div className="popup_inner" ref={pop}>
            <img id="close_btn" alt="" src={close} onClick={()=>{
              setIsTrue(false)
              setError(false)
              setInputsDate({
                Name:'',
                PhoneNumber: '',
                Email: '',
                message:''
              })
              }}/>
          <img alt="bcg" src={bacground} id="bcg_Img"/> 
             <div className="form_inner">
              <h2>{t("Fill_out_the_appTitle")}</h2>
              <p> {t("Fill_out_the_appSubtitle")}</p>
              {
                error?
                <div>
                  <p style={{color: 'red'}}>{t("error1")}</p>
                </div>
                :""
  
              }
                  <form className="form"  onSubmit={Submit} ref={form} onChange={Change}>
                      <input className={"input_class"} id="name" type="name" name="Name" placeholder={t("Your_name")} value={inputsDate.Name}
                       style={{border:error && !inputsDate.Name ? "1px solid red" : ""}}
                             onChange={(e)=>{setInputsDate({...inputsDate,Name:e.target.value})
                             }}>
                      </input>
                      <input className={"input_class"} id="Phone_number" name="Phone" type="tel" placeholder={t("Your_phone_number")} value={inputsDate.PhoneNumber}
                              style={{border:error && !inputsDate.PhoneNumber ? "1px solid red" : ""}}
                              onChange={handleChange}> 
                     
                      </input>
                      <input className={"input_class"} id="Email" name="Email" type="email" placeholder="example@mail.ru" value={inputsDate.Email}
                              style={{border:error && !inputsDate.Email ? "1px solid red" : ""}}
                              onChange={(e)=>{setInputsDate({...inputsDate,Email:e.target.value})}}>
                      </input>

                      {Array.isArray(Shop_data) && Shop_data.map((el, index) => {
                          return (
                              <input
                                  key={index}
                                  type="hidden"
                                  id={`message_${index}`}
                                  name={`message`}
                                  value={`< Սարքի անվանումը ՝ ( ${ el.name} ) Սարքի գինը ՝ ( ${el.price} ) >` || inputsDate.info}
                              />
                          );
                      })}
                      <p id="Optional_field">{t("Optional_field")}</p>
                      {
                         ( isLoading && !error )? <button  id="call_me_btn" >{t("sending")}</button>: <button type="submit" id="call_me_btn">{t("Call_me")}</button>
                      }
                     
                  </form>
             </div>
          </div>
          </div> :  isAccepted ? <Accepting isAccepted={isAccepted} /> : ""}

        </Fragment>
    ) 
  }

export default Popup