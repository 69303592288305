import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Swiper_carousel.css';
import {Navigation, Pagination, Mousewheel, Keyboard, FreeMode, Autoplay} from 'swiper/modules';

import partner1 from "../../img/logoPartners/18817110logo.png";
import partner2 from "../../img/logoPartners/arigato.png";
import partner3 from "../../img/logoPartners/5d15b8ab78bb6web_fabrica.png";
import partner4 from "../../img/logoPartners/866ddf71-7a70-46ae-b877-ee18443e8678.png";
import partner5 from "../../img/logoPartners/Central-Hotellogo.png";
import partner6 from "../../img/logoPartners/Doshi-Sushi-logo.png";
import partner7 from "../../img/logoPartners/KFC_logo.png";
import partner8 from "../../img/logoPartners/Logo_Paul.png";
import partner9 from "../../img/logoPartners/Vector.png";
import partner10 from "../../img/logoPartners/burgery.png";
import partner11 from "../../img/logoPartners/aquatek_web_1.png";
import partner12 from "../../img/logoPartners/araks-hotel_web_1.png";
import partner13 from "../../img/logoPartners/artashi-mot_web_1.png";
import partner14 from "../../img/logoPartners/black-star-burger.png";
import partner15 from "../../img/logoPartners/cactus_web_1.png";
import partner16 from "../../img/logoPartners/crystal_web_1.png";
import partner17 from "../../img/logoPartners/Frame 2.png";
import partner18 from "../../img/logoPartners/for_web.png";
import partner19 from "../../img/logoPartners/good-time_web_1.png";
import partner20 from "../../img/logoPartners/kecharis_web_1.png";
import partner21 from "../../img/logoPartners/yeraz-restaurant_web_1.png";
import partner22 from "../../img/logoPartners/locals_web_1.png";
import partner23 from "../../img/logoPartners/logo-l-1.png";
import partner24 from "../../img/logoPartners/multi-group_web_1.png";
import partner25 from "../../img/logoPartners/multigrandhotel.png";
import partner26 from "../../img/logoPartners/pahest_web_1.png";
import partner27 from "../../img/logoPartners/pizza-di-roma_web_1.png";
import partner28 from "../../img/logoPartners/pizza-hut_web_1.png";
import partner29 from "../../img/logoPartners/queen-burger_web_1.png";
import partner30 from "../../img/logoPartners/rossia-mall_web_1.png";
import partner31 from "../../img/logoPartners/santafe_web_1.png";
import partner32 from "../../img/logoPartners/shangri-la_web_1.png";
import partner33 from "../../img/logoPartners/sochi-palace_web_1.png";
import partner34 from "../../img/logoPartners/valensia_web_1.png";
import partner35 from "../../img/logoPartners/wanderland.png";
import partner36 from "../../img/logoPartners/yeraz-park_web_1.png";
import partner37 from "../../img/logoPartners/vallex-garden_web_1.png";
import partner38 from "../../img/logoPartners/water-world_web_1.png";
import partner39 from "../../img/logoPartners/winter-park_web_1.png";
import partner40 from "../../img/logoPartners/gntunik.png";
import partner41 from "../../img/logoPartners/yakitoriya-logo-ru.png";
import partner42 from "../../img/logoPartners/cinema.png";
import partner43 from "../../img/logoPartners/akunq.jpg";
import partner44 from "../../img/logoPartners/kinohayastan.png";
import partner45 from "../../img/logoPartners/download.jpg";
import partner46 from "../../img/logoPartners/magicland.png";
import partner47 from "../../img/logoPartners/ponchikmonchik.jpg";
import partner48 from "../../img/logoPartners/bambak.png";
import partner49 from "../../img/logoPartners/gargoje.jpg";
import partner50 from "../../img/logoPartners/sasfood.png";
import partner51 from "../../img/logoPartners/parkavenu.jpg";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";


export function Swiper_carousel() {
    const { t } = useTranslation();

    const partnerLogos=[
        partner1,partner2,partner3,partner4,partner5,partner6,partner7,partner8,partner9,partner10,
        partner11,partner12,partner13,partner14,partner15,partner16,partner17,partner18,partner19,partner20,
        partner21,partner22,partner23,partner24,partner25,partner26,partner27,partner28,partner29,partner30,
        partner31,partner32,partner33,partner34,partner35,partner36,partner37,partner38,partner39,partner40,
        partner41,partner42,partner43,partner44,partner45,partner46,partner47,partner48,partner49,partner50,
        partner51
    ]

    return (
        <div className={'Swiper_main'}>
            <h2 className={'Swiper_h2'}>{t("They_trust_us")}</h2>
            <Swiper
                slidesPerView={window.innerWidth <= 980 ? 4 : 6}
                spaceBetween={30}
                speed={1500}
                height={window.innerWidth <= 980 ? 150 :  100}
                freeMode={true}
                pagination={{
                    dynamicBullets: true,
                }}
                slidesPerGroup={window.innerWidth <= 980 ? 4 : 6}
                grabCursor={true}
                loop={true}
                navigation={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay,FreeMode, Pagination,Navigation]}
                className="mySwiper"
            >
            {partnerLogos.map((elem,index) => (
                            <SwiperSlide key={index} >
                                    <img alt="'_'" src={elem} />
                            </SwiperSlide>
            ))}
            </Swiper>
        </div>
    );
}