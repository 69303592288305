import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import React, { useEffect } from 'react';

function App() {

  let ScrollToTop =() => {
    const { pathname } = useLocation()
    useEffect(() => {
      window.scrollTo(0,0)
    },[pathname])

    return null
  }

ScrollToTop()

  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Layout />}/>
      </Routes>
    </div>
  );
}

export default App;
