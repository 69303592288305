import Button from "../Button/Button";
import "./Cinema.css"
import headerPic from "../../img/Cinema/header1.png";
import headerPic2 from "../../img/Cinema/header2.png";
import popcorn from "../../img/Cinema/popcorn.png";
import comp from "../../img/Cinema/macbook2.png";
import bacground from "../../img/EntertainmentCenters/vector.png";
import img1 from "../../img/Cinema/comp.png";
import img2 from "../../img/Cinema/terminal.png";
import img3 from "../../img/Cinema/macbook.png";
import block4img from "../../img/Cinema/block4.png";
import block6img from "../../img/Cinema/block6.png";
import icon1 from "../../img/Cinema/filetext.svg";
import icon2 from "../../img/Cinema/icon.svg";
import icon3 from "../../img/Cinema/globe.svg";
import icon4 from "../../img/Cinema/gift.png";
import icon5 from "../../img/Cinema/time.svg";
import {useTranslation} from "react-i18next";
import {Swiper_carousel} from "../Swiper_carousel/Swiper_carousel";


function Cinema({setIsTrue}) {
    const { t } = useTranslation();

    return (
     <div>
{/* header */}
      <div className="PublicFood_header">
        <div className="PublicFood_header_text">
          <h1>
            {t("Cinema_title")} <span>{t("Cinema_title_2")}</span>{t("ov")}
          </h1>
        </div>
        <div className="Cinema_flex_container">
          <div className="Cinema_flex_element">{t("CinemaHeader_text1")}</div>
          <div className="Cinema_flex_element">{t("CinemaHeader_text2")}</div>
          <div className="Cinema_flex_element">{t("CinemaHeader_text3")}</div>
        </div>
        <div className="PublicFood_header_picture">
          <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
          <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="PublicFood_button_component">
            <Button setIsTrue={setIsTrue}/>
          </div>
        </div>
      </div>
{/* block 2 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
          <div className="PublicFood_support_text PublicFood_block2_text">
            <h4 id="cinema_text2">{t("Cinema_text")}</h4>
          </div>
          <div className="PublicFood_handAndPhone"> </div>
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img id="popcorn"  alt="phone" src={popcorn} />
      </div>
{/* block 3 */}
    <div>
        <div className="second_title">
          <h2 className="title_h2">{t("Cinema_block2_title")}</h2>
          <p className="subtitle_h2"> {t("Cinema_block2_subtitle")}</p>
        </div>
        <div className="categories_slide_wrapper">
          <div className="categories_flex">
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text_item">
                  {t("Cinema_block2_text1")}
                </p>

                <p className="equipment_subtext">{t("Cinema_block2_text2")}</p>
              </div>
              <div className="element_img cinema_img">
                <img className="equipment_img_item" alt="img" src={img1} />
              </div>
            </div>
            <div className="equipment_element_item" id="column">
              <div className="text_wrapper">
                <p className="equipment_text_item">
                  {t("Cinema_block2_text3")}
                </p>

                <p className="equipment_subtext">{t("Cinema_block2_text4")}</p>
              </div>
              <div className="element_img ">
                <img className="equipment_img_item" id="equipment_img_terminal" alt="img" src={img2} />
              </div>
            </div>
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text_item">
                  {t("Cinema_block2_text5")}
                </p>

                <p className="equipment_subtext">{t("Cinema_block2_text6")}</p>
              </div>
              <div className="element_img cinema_img">
                <img className="equipment_img_item" alt="img" src={img3} />
              </div>
            </div>
          </div>
        </div>
    </div>
{/* block 4 */}
      <div className="cinema_flex_box">
        <div className="cinema_text">
          <h2>{t("Cinema_block4_title")}</h2>
          <p> {t("Cinema_block4_text1")}</p>
          <p> {t("Cinema_block4_text2")}</p>
        </div>
        <div className="cinema_img">
          <img alt="" src={block4img}></img>
        </div>
      </div>
{/* block 5 */}
      <div className="PublicFood_flex_wrapp">
        <h2 id="cinema_block5_title">{t("Cinema_block5_title")}</h2>
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon1} />
                    </div>
                    <h3>{t("Cinema_block5_text1")}</h3>
                    <p> {t("Cinema_block5_text2")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon2} />
                    </div>
                    <h3>{t("Cinema_block5_text3")}</h3>
                    <p> {t("Cinema_block5_text4")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon3} />
                    </div>
                    <h3>{t("Cinema_block5_text5")}</h3>
                    <p> {t("Cinema_block5_text6")}</p>
                </div>
            </div>
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon4} />
                    </div>
                    <h3>{t("Cinema_block5_text7")}</h3>
                    <p> {t("Cinema_block5_text8")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon5} />
                    </div>
                    <h3>{t("Cinema_block5_text9")}</h3>
                    <p> {t("Cinema_block5_text10")}</p>
                </div>
                <div className="PublicFood_element empty_div"></div>
               
            </div>
      </div>
{/* block 6 */}
    <div className="cinema_flex_box">
        <div className="cinema_text">
          <h2>{t("Cinema_block4_title")}</h2>
          <h4>{t("Cinema_block6_text1")}</h4>
          <p> {t("Cinema_block6_text2")}</p>
          <h4>{t("Cinema_block6_text3")}</h4>
          <p> {t("Cinema_block6_text4")}</p>
          <p> {t("Cinema_block6_text5")}</p>
        </div>
        <div className="cinema_img">
          <img alt="" src={block6img}></img>
        </div>
    </div>

{/* block 7*/}
      <div className="PublicFood_block">
            <div className="PublicFood_support_text">
            <h2> {t("Cinema_block7_title")}</h2>
              <p>{t("Cinema_block7_text1")}</p>
                <div className="PublicFood_text">
                      <div className="PublicFood_text_column" > 
                        <p>
                            {t("Cinema_block7_text2")}
                        </p>
                        <p> 
                            {t("Cinema_block7_text3")}
                        </p>
                        
                      </div>
                      <div className="PublicFood_text_column" > 
                        <p>
                            {t("Cinema_block7_text4")}
                        </p>
                        <p> 
                            {t("Cinema_block7_text5")}
                        </p>
                        
                      </div>
                </div>
            </div>
            <div className="PublicFood_iphone cinem_block7_img">
            <img id="macbook" alt="phone" src={comp} />
            </div>
            <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
      </div>
{/* sliding-line  */}
{/* <SlideLine lang={lang}/>*/}
         <Swiper_carousel/>

{/* Blue_block */}
       <div className="PublicFood_Blue_block">
            <p>{t("blue_block_text7")}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
      setIsTrue(true)
    }}>
                {t("Calculate_the_cost")}
                </button>
            </div>
        </div>
     </div>
    ) 
  }
  
  export default Cinema