import './Navbar_.css'
import React from "react";
import { useTranslation } from "react-i18next";
import {
    Navbar,
    Collapse,
    Typography,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import {
    ChevronDownIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "../../img/favicon.svg"
import {
    LinkedinIcon,
    IconApplicationEditOutline,
    IconRestaurantOutline,
    IconPointOfSale,
    IconDeviceGamepad2,
    IconDumbbell,
    IconHotelLine,
    IconFastFoodOutline,
    IconMovieOpenSettingsOutline,
    R_keeper,
    Pcassa,
    Game_Keeper, RestA, Abonement, Premera
} from "../../img/Component_svg/Component_svg";
import Shelter from "../../img/Component_svg/Shelter.svg"
import Language from "../Language/Language";
import {useNavigate} from "react-router-dom";

function ListItem_active (event,setOpenNav) {
    event.target.style.backgroundColor ='#fff'
    if(setOpenNav){
        setOpenNav.setOpenNav(false)
    }
}

function NavListMenu_one(setOpenNav) {
    const { t } = useTranslation();
    const Navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const navListMenuItems_one = [
        {
            title:t('Restaurant_app'),
            description: t('MobileApp_title_part1_item'),
            icon: IconApplicationEditOutline,
            navigate:"MobileApp",
        },
        {
            title: t('Conceptual_Restaurant'),
            description: t('PublicFood_title_ov'),
            icon: IconRestaurantOutline,
            navigate:"PublicFood",
        },
        {
            title: t('Retail_outlet'),
            description: t('Retail_header_title_item'),
            icon: IconPointOfSale,
            navigate:"Retail",
        },
        {
            title: t('Entertainment_Center'),
            description:  t('EnterCenters_title_item'),
            icon: IconDeviceGamepad2,
            navigate:"EntertainmentCenters",
        },
        {
            title: t('Sports_facility'),
            description: t('Fitness_title_part1_item'),
            icon: IconDumbbell,
            navigate:"Fitness",
        },
        {
            title:t('Hotel'),
            description: t('Hotels_title_item'),
            icon: IconHotelLine,
            navigate:"Hotels",
        },
        {
            title: t('Cinema'),
            description:t('Cinema_title_item'),
            icon: IconMovieOpenSettingsOutline,
            navigate:"Cinema",
        },
        {
            title: t('Fast_food'),
            description: t('FastFood_title_item'),
            icon: IconFastFoodOutline,
            navigate:"FastFood",
        },
    ];

    const renderItems = navListMenuItems_one.map(
        // eslint-disable-next-line no-unused-vars
        ({ icon, title, description,navigate }, key) => (
            <a  key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg "  onClick={() => {
                    Navigate(navigate)
                    setOpenNav.setOpenNav(false)
                    setIsMobileMenuOpen(false)
                }}>
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 " >
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            id={'ListItem'}
                            className="flex items-center gap-2 py-2 pr-4 text-lg font-medium text-gray-900 "
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={(event) => {
                                setIsMobileMenuOpen((cur) => !cur)
                                ListItem_active(event)
                            }}
                        >
                            {t('Solutions')}
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block  ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavListMenu_two(setOpenNav) {
    const { t } = useTranslation();
    const Navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const navListMenuItems_two = [
        {
            title:'r_keeper',
            description: t("PublicFood_title_ov"),
            icon: R_keeper,
            navigate:"PublicFood",
        },
        {
            title: 'Shelter',
            description: t("Hotels_title_item"),
            icon:() => <img alt={"'_'"} src={Shelter}/>,
            navigate:"Hotels",
        },
        {
            title: "Pcassa",
            description: t("Retail_header_title_item"),
            icon: Pcassa,
            navigate:"Retail",
        },
        {
            title: "RestA",
            description: t("MobileApp_title_part1_item"),
            icon: RestA,
            navigate:"MobileApp",
        },
        {
            title: t("Software_Premiere"),
            description: t("Cinema_title_item"),
            icon: Premera,
            navigate:"Cinema",
        },
        {
            title: "Game-Keeper",
            description: t("EnterCenters_title_item"),
            icon: Game_Keeper,
            navigate:"EntertainmentCenters",
        },
        {
            title: t("Software_Subscription"),
            description: t("Fitness_title_part1_item"),
            icon: Abonement,
            navigate:"Fitness",
        },
    ];

    const renderItems = navListMenuItems_two.map(
        ({ icon, title, description,navigate}, key) => (
            <a  key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg" onClick={() => {
                    Navigate(navigate)
                    setOpenNav.setOpenNav(false)
                    setIsMobileMenuOpen(false)
                }}>
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 text-lg font-medium text-gray-900 "
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={(event) => {
                                setIsMobileMenuOpen((cur) => !cur)
                                ListItem_active(event)
                            }}
                        >
                            {t('Products')}
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

function NavList(setOpenNav) {
    const Navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <List id={'Navbar_content'} className="mt-4 mb-2 p-0 lg:mt-0 lg:mb-0 lg:flex-row">
        <NavListMenu_one setOpenNav={setOpenNav.setOpenNav} />
        <NavListMenu_two setOpenNav={setOpenNav.setOpenNav} />
        <Typography
            as="div"
            href={""}
            variant="small"
            color="blue-gray"
            className="font-medium text-lg"
        >
            <ListItem
                id={'ListItem'}
                className="flex items-center gap-2 py-2 pr-4 text-lg "
                onClick={(e) => {
                    ListItem_active(e,setOpenNav)
                    Navigate("/Hardware")
                }}
            >
                {t('Equipment')}
            </ListItem>
        </Typography>

            <Typography
                as="div"
                variant="small"
                color="blue-gray"
                className="font-medium text-lg	"
            >
                <ListItem
                    id={'ListItem'}
                    className="flex items-center gap-2 py-2 pr-4 text-lg"
                    onClick={(e) => {
                        ListItem_active(e,setOpenNav)
                        setTimeout(() => {
                            window.scrollTo({top: document.documentElement.scrollHeight, behavior: 'smooth' })
                        },[0])

                    }}
                >
                    {t('Contacts')}
                </ListItem>
            </Typography>

            <Typography
                as="div"
                variant="small"
                color="blue-gray"
                className="font-medium text-lg	"
            >
                <ListItem
                    id={'ListItem'}
                    className="flex items-center gap-2 py-2 pr-4 text-lg"
                    onClick={(e) => {
                        ListItem_active(e,setOpenNav)
                        Navigate("/AboutUs")
                    }}
                >
                    {t('AboutUs')}
                </ListItem>
            </Typography>

            <Typography
                as="div"
                variant="small"
                color="blue-gray"
                className="font-medium text-lg	"
            >
                <ListItem
                    id={'ListItem'}
                    className="flex items-center gap-2 py-2 pr-4 text-lg"
                    onClick={(e) => {
                        ListItem_active(e,setOpenNav)
                        Navigate("/Blog")
                    }}
                >
                    {t('Blog')}
                </ListItem>
            </Typography>

            <Typography
                as="div"
                variant="small"
                color="blue-gray"
                className="flex items-center gap-2 py-2 pl-4 pr-4 text-lg "
            >
                   <Language />
            </Typography>

            <Typography
                as="div"
                variant="small"
                color="blue-gray"
                className="phone_activ flex flex-col text-sm items-center gap-0 py-2 pl-4 pr-4 "
            >
                <span>+374 99 545445</span>
                <a href={"mailto:info@ekey.am"}>info@ekey.am </a>
            </Typography>
            <div className="icon_navbar flex gap-4 text-blue-gray-900 sm:justify-center py-2 pl-4 pr-4">
              <Typography as="a" href="https://www.facebook.com/EkeyTech" target="_blank" rel="noreferrer" className="opacity-80 transition-opacity hover:opacity-100">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            </Typography>
              <Typography as="a" href="https://www.instagram.com/ekeytec"  target="_blank" rel="noreferrer" className="opacity-80 transition-opacity hover:opacity-100">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            </Typography>
              <Typography as="a" href="https://www.linkedin.com/company/ekeyam" target="_blank" rel="noreferrer" className="opacity-80 transition-opacity hover:opacity-100 fi fi-brands-linkedin" >
                <LinkedinIcon />
            </Typography>
            </div>
        </List>
    );
}

export function Header() {
    const [openNav, setOpenNav] = React.useState(false);
    const Navigate = useNavigate()

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    return (
        <Navbar id={'Navbar'} className=" fixed max-w-full mx-auto py-2.5 bg-opacity-100">
            <div className="flex items-center justify-between text-blue-gray-900">
                <Typography
                    as="div"
                    variant="h6"
                    className="mr-4 cursor-pointer py-1.5 lg:ml-2 w-20"
                    onClick={() => {
                        Navigate('/')
                        setOpenNav(false)
                        setTimeout(() => {
                            window.scrollTo({top: 0, behavior: 'smooth' })
                        },[0])
                    }}
                >
                    <img className={"w-full"} alt={"'_'"} src={Logo} />
                </Typography>
                <div  className="hidden lg:block">
                    <NavList setOpenNav={setOpenNav} />
                </div>
                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <NavList setOpenNav={setOpenNav} />
            </Collapse>
        </Navbar>
    );
}