import CryptoJS from "crypto-js";

export class StorageService {
    static setStorage(key, value) {
        try {
            const jsonValue = JSON.stringify(value);

            // Ensure the key exists in environment variables
            const privateKey = process.env.REACT_APP_HASH_KEY;

            if (!privateKey) {
                throw new Error("Encryption key is not defined in environment variables.");
            }

            const hashValue = CryptoJS.AES.encrypt(jsonValue, privateKey);

            localStorage.setItem(key, hashValue.toString());
            return hashValue.toString();
        } catch (error) {
            console.error("Error setting storage:", error);
            return null;
        }
    }

    static getStorage(key) {
        try {
            const storage = localStorage.getItem(key);

            if (storage) {
                const privateKey = process.env.REACT_APP_HASH_KEY;

                if (!privateKey) {
                    throw new Error("Encryption key is not defined in environment variables.");
                }

                const hashValue = CryptoJS.AES.decrypt(storage, privateKey);

                return JSON.parse(hashValue.toString(CryptoJS.enc.Utf8));
            }

            return null;
        } catch (error) {
            console.error("Error getting storage:", error);
            return null;
        }
    }

    static removeStorage(key) {
        localStorage.removeItem(key);
    }
}