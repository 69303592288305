import "./Notifications.css"
import {Badge} from "@material-tailwind/react";
import {
    List,
    ListItem,
    ListItemPrefix,
    Avatar,
    Card,
    Typography,
} from "@material-tailwind/react";
import {IconDelete, IconShopping} from "../../img/Component_svg/Component_svg";
import React from "react";
import {useTranslation} from "react-i18next";
import {setShopremove} from "../../redux/features/shop/shopSlice";
import {useDispatch} from "react-redux";

export function BadgeDefault({Shop_data,setIsTrue}) {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const handleRemoveItem = (id) => {
        dispatch(setShopremove(id));
    };

    return (
    <section className={'flex justify-center items-center'}>
        <Badge content={Shop_data.length ? Shop_data.length : 0}>
            <div className={'Badge'}>
                <IconShopping />
                {(Array.isArray(Shop_data) && Shop_data.length > 0) ? <div className={'Badge_main'}>
                    <Card className="Badge_main_scroll w-[28rem] max-h-[500px] overflow-y-auto p-2">
                        <List className="Badge_main_scroll overflow-x-auto whitespace-nowrap">
                            {Array.isArray(Shop_data) &&
                                Shop_data.map((elem, index) => (
                                    <ListItem key={index} ripple={false} className="py-1 pr-1 pl-4">
                                        <ListItemPrefix>
                                            <img variant="circular" className={'rounded-none w-16 h-14'} alt={elem.name}
                                                 src={elem.img}
                                            />
                                        </ListItemPrefix>
                                        <Typography variant="h6" color="blue-gray" className="text-sm w-full flex justify-between items-center">
                                            <div>{elem.name}</div>
                                            <div onClick={() => {
                                                handleRemoveItem(elem.id)
                                            }}><IconDelete/></div>
                                        </Typography>
                                    </ListItem>
                                ))}
                        </List>
                    </Card>
                </div> : null}
            </div>
        </Badge>

        <div className={"ml-3 cursor-pointer"} onClick={() => setIsTrue(true)}>
            {t("Order")}
        </div>
    </section>

    );
}